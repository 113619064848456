import React from 'react';
import { Link } from 'react-router-dom';

const WhatsApp = ({ data }) => {
    const phoneNumber = '+971564532562';
    const preText = `Hello, I'm interested in the ${data?.product} Tablet. Can you provide me with more details? Thanks! `;
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(preText)}${encodeURIComponent(data?.pageLink)}`;
    return (
        <Link to={whatsappLink} className='border-blue-700 text-blue-700 border px-8 py-2 rounded-full' target='_blank'>
            Order Now
        </Link>
    );
};

export default WhatsApp;